.home-container {
    color: white;
    font-family: 'Poppins', sans-serif;

}

.quick-about {
    font-family: 'Poppins', sans-serif;

    font-size: 12px;
    margin: 10%;
}

.build {
    font-size: 18px;
    line-height: 50px;
    margin: 100px 20px
}

.easy {
    margin: 50px 0;
}
.skill-container {
    display: flex;
    flex-direction: column;
    height: 30%;
}
.skills-icon {
    width: 100px;
    position: absolute;
    margin: -90px -50px;
}
.skill-heading {
    background-color: rgba(185, 173, 192, 0.048);
    height: 30px;
}

.icon-container1 {
    background-color: rgba(220, 211, 224, 0.151);
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    margin-bottom: 50px;
}

.lang-icon {
    max-width: 15%;
    max-height: 3em;
    margin: 4%;
    padding: 0 0;
}

@media screen and (min-width: 500px) {
    .quick-about {
        font-size: 20px;
        line-height: 35px;
        
    }

    .build {
        font-size: 25px;
    }
    .icon-container1 {
        justify-content: space-evenly;
    }

    .lang-icon {
        margin: 3%;
        max-width: 50%;
        max-height: 5em;
    }

    .easy {
        font-size: 23px;
        margin: 5%;
    }
}