.nav-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}
.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 50px;
  margin: 10px;
  text-transform: uppercase;
  background-color: transparent;
  color: white;
  text-decoration: none;
  font-family: "Bebas Neue", sans-serif;

  letter-spacing: 2px;
  font-weight: 600;
  font-size: 13px;
}

.active {
  animation: spaceapart 0.5s forwards ease-in-out;

}

.nav:active {
  color:rgba(151, 240, 8, 0.603);
}
.nav.nav-home {
  margin-top: 10px;
  animation: unblur 1s ease backwards 500ms
  
}
.nav.nav-works {
  animation: unblur 1s ease backwards 750ms
  
}
.nav.nav-resume {
  margin-bottom: 10px;
  animation: unblur 1s ease backwards 1000ms
  
}
@media screen and (max-width:480px) {
.active {
  font-size: 15px;
}
}

@keyframes unblur {
  0% {
    filter: blur(1.5em);
  }

  100% {
    filter: blur(0);
  }
}

@keyframes spaceapart {
  0% {
    letter-spacing: 1px;
  
  }
  100% {
    letter-spacing: 10px;
  }
}

@media screen and (min-width: 500px) {

  .nav-main-container {
    display: flex;
    align-items: flex-end;
    width: max-content;
    height: max-content;
    margin-right: 18%;
    

  }
  .nav {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: max-content;
  box-shadow: none;
  text-transform: uppercase;
  background-color: transparent;
  color: white;
  text-decoration: none;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 6px;
  font-weight: 300;
  font-size: 16px;
  margin-left: 7%;
  border: none;
  }

  .nav:active {
    background-color: rgba(255, 166, 0, 0.267);
  }

  .nav:hover {
    color:rgba(151, 240, 8, 0.603);
  }

  
  .nav.nav-resume {
    padding-right: 5px;
    
  }

  .active {
    font-size: 20px;
    color:rgba(151, 240, 8, 0.603)
  }
  

}