.notfound-text-container {
    color: white;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    font-family: "Poppins", sans-serif;
    
}

.back-button {
    text-decoration: none;
    color: rgba(250, 250, 250, 0.979);
    background-color: rgb(0, 0, 0);
    border: 2px solid grey;
    font-size: 1em;
    padding: 5%;
    max-height: 50%;
    margin: 5% 10%;
    max-width: 70%;
    z-index: 1000;
    margin: 2% auto;
    filter: drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.639));
    font-family: "Poppins", sans-serif;
    border-radius: 15%;
}
.column404 {
    flex-direction: column;
    margin: 0;
    
}

.row404 {
    display: flex;
    justify-content: space-evenly;
}
.four1 {
    animation: zoomie 10s infinite ease-in-out
}
.zero {
    animation: zoomie 9s infinite ease-in;
    font-size: 40px;

}

.four2 {
    animation: zoomie 11s infinite ease-in;
}

.four3 {
    animation: zoomie1 2s infinite ease-in
}

.zero2 {
    animation: zoomie1 4s infinite ease-in;
    font-size: 16px
    
}

.four4 {
    animation: zoomie1 5s infinite ease-in;
    font-size: 14px
}

.four5 {
    animation: zoomie 2s infinite ease-in-out

}

.four6 {
    animation: zoomie 3s infinite ease-in-out

}

.zero3 {
    animation: zoomie 4s infinite ease-in-out

}
@keyframes zoomie {

    0% {
        transform: translateY(0px);
        filter: drop-shadow(5px 50px 2px rgba(17, 147, 34, 0.596));

        
        
    }
    20% {
        transform: translateY(-200px);
        filter: drop-shadow(-10px 40px 1px rgba(185, 13, 13, 0.692));

        
    }
    40% {
        transform: translateY(20%);
        filter: drop-shadow(5px 15px 3px rgba(20, 161, 185, 0.753));

        
    }
    60% {
        transform: translateY(-400%);
        filter: drop-shadow(20px 10px 4px rgba(214, 201, 15, 0.925));

        
    }
    80% {
        transform: translateY(-40%);
        filter: drop-shadow(5px 40px 2px rgba(250, 246, 246, 0.986));

        

    }
    100% {
        transform: translateY(0);
        filter: drop-shadow(5px 2px 10px rgba(28, 235, 21, 0.925));

        

    }
}

@keyframes zoomie1 {

    0% {
        transform: translateX(0px);
        filter: drop-shadow(5px 50px 2px rgba(147, 86, 17, 0.596));

        
    }
    20% {
        transform: translateX(-5%);
        filter: drop-shadow(5px 50px 2px rgb(143, 17, 147));

    }
    40% {
        transform: translateX(5%);
        filter: drop-shadow(5px 50px 2px rgba(222, 54, 12, 0.966));

    }
    60% {
        transform: translateX(2%);
        filter: drop-shadow(5px 50px 2px rgba(62, 41, 252, 0.596));

    }
    80% {
        transform: translateX(5%);
        filter: drop-shadow(5px 50px 2px rgba(247, 4, 16, 0.952));


    }
    100% {
        transform: translateX(0px);
        filter: drop-shadow(5px 50px 2px rgba(250, 213, 1, 0.596));


    }
}

@keyframes zoomie2 {

    0% {
        
        
    }
    20% {
        
    }
    40% {
        
    }
    60% {
        
    }
    80% {
        

    }
    100% {
        

    }
}

@media screen and (min-width: 500px) {
    .back-button {
    text-decoration: none;
    color: rgba(250, 250, 250, 0.979);
    background-color: rgb(0, 0, 0);
    border: 2px solid grey;
    font-size: 1em;
    padding: 1% 2%;
    max-height: 50%;
    margin: 5% 10%;
    max-width: 70%;
    z-index: 1000;
    margin: 2% auto;
    filter: drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.639));
    border-radius: 15%;

    }
}