footer {
  
  box-shadow: 2px 0px 2px 3px rgba(202, 202, 202, 0.185);
  height: 100px;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
}

.more {
  color: white;
  font-size: 12px;
}
.linkedin {
  width: 45px;
}

.contact-me {
  width: 41px;
  opacity: 0.8;


}

.linkedin, .github, .contact-me {
  text-align: center;
  font-size: 10px;
  color: white;
  text-decoration: none;
}

.github {
  width: 40px;
}

a > img {
  margin-right: 30px;
  padding: 2px;
  /* box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, 0.432); */
  border-radius: 5px;
}

@media screen and (min-width: 500px) {
  
    .more {
    font-size: 100%;
  }

  .linkedin, .github, .contact-me {
    text-align: center;
    font-size: 15px;
    color: white;
    text-decoration: none;
    max-width: 100%;
  }
}
